import React, { useState, useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { AuthContext } from '../../../../services/auth/AuthProvider';

import Tab from '../../../../components/Tab';
import Tabs from '../../../../components/Tabs';
import PageHeader from '../../../../components/PageHeader';
import { Loader } from '@axeedge/go-teacher-components';

import ClassViewTeachers from './ClassViewTeachers';
import ClassViewPacks from './ClassViewPacks';
import Students from '../Students';
import { GET_CLASS_QUERY } from '../../services/graphql';
import { useTranslation } from 'react-i18next';
import styles from './ClassView.module.scss';
import ls from 'local-storage'

const TABS = {
    pupils: 1,
    teachers: 2,
    bookPacks: 3
}

const ClassView = ({ classid }) => {

    const { t } = useTranslation(['classes', 'common']);

    const auth = useContext(AuthContext);
    const { currentUser: teacher } = auth;

    const [selectedTab, setSelectedTab] = useState(TABS.pupils);

    const { data, error, loading } = useQuery(GET_CLASS_QUERY, {
        variables: {
            id: classid
        },
        fetchPolicy: 'cache-and-network'
    });

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <Loader />
    }



    if (data && data.studentsClass) {
        return (
            <>
                <PageHeader title={data.studentsClass.name} backTo={'/classes'} />
                <Tabs withPanel className='u-m-base-3 hidden-print'>
                    <Tab onClick={() => setSelectedTab(TABS.pupils)} selected={selectedTab === TABS.pupils}>{t('common:pupils')}</Tab>
                    <Tab onClick={() => setSelectedTab(TABS.teachers)} selected={selectedTab === TABS.teachers}>{t('teachers_and_settings')}</Tab>
                    {/* {ls('country') !== 'US' &&
                        <Tab onClick={() => setSelectedTab(TABS.bookPacks)} selected={selectedTab === TABS.bookPacks}>{t('common:gowrite_book_packs')}</Tab>
                    } */}
                </Tabs>

                {selectedTab === TABS.pupils ?
                    <div className={styles.container}>
                        <Students currentUser={teacher} school={teacher.school} classData={data.studentsClass} students={data.studentsClass.students} />
                    </div> :
                    selectedTab === TABS.teachers && (
                        <ClassViewTeachers currentUser={teacher} classData={data.studentsClass} />
                    )}


            </>
        );
    }

    return null;
}

export default ClassView;
