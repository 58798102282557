import { APPS } from '@axeedge/go-shared-utils';

export const APP_NAME = APPS.go_portal_teacher;
export const APP_NAME_US = APPS.boom_math_portal_teacher;

//: APPS.boom_math_portal_teacher

export const PACK_EXTRA_COPY_PRICE = 6.99;//temporary change for Leavers book, then back to 7.99
export const READ_PRICE_PER_STUDENT = 1;
export const MATH_PRICE_PER_STUDENT = 2.99;

export const SUBSCRIPTION_INTENT = {
    read: 1,
    write: 2,
    math: 3,
    leavers: 4
}

export const SIGNUP_REASON = {
    createSchool:1,
    requestAccess: 2
}

export const READ_SUB_DURATION = {
    oneYear: 1,
    twoYear: 2
}

export const MATH_SUB_STATUS = {
    active: 1,
    expired: 2, // subscription cannot be renewed due to rejected payment
    cancelled: 9, // cancelled during trial only
    cancelled_renewal: 10 // cancelled after payment started
}

export const PROMO_CODES = {
    writeToRaise: 'WTR',
    goReadGrandTour: 'RGT'
}

export const SCHOOL_SETUP_STEPS = {
    schoolStepDone: 0,
    classesStepDone: 1,
    finalStepDone: 2
}

export const WONDE_SYNC_STEPS = {
    syncTeachers: 0,
    syncStudentsUpn: 1,
    syncStudentsSuggested: 2,
    syncStudentsManual: 3,
    syncClasses: 4,
    syncExtraStaff: 5,
    syncCompleted: 99
}

export const SCHOOL_MATH_SETUP_STEPS = {
    schoolStepDone: 0,
    classesStepDone: 1,
    staffAccessDone: 2,
    finalStepDone: 3
}

export const WEEK_STARTS = {
    1: 'Monday - Monday',
    2: 'Tuesday - Tuesday',
    3: 'Wednesday - Wednesday',
    4: 'Thursday - Thursday',
    5: 'Friday - Friday',
    6: 'Saturday - Saturday',
    7: 'Sunday - Sunday'
}

export const CLASS_TYPE = {
    registration: 1,
    other: 2,
}

export const RENEWAL_STATUS = {
    new: 1,
    cancelled: 2,
    processed: 3
}

export const schoolQuery = `
id
avatarUrl
name
address
address2
acceptedPromotions
newYearStep
wondeAccessApprovedAt
wondeSyncStep
wondeId
wondePreapproved
isBeta
country {
    id
    name
    code
}
state {
    id
    name
    code
}
billingProfile {
    id
    address
    address2
    city
    state {
        id
        code
    }
    country {
        id
        code
        name
    }
    zip
    companyName
    contactPerson
    contactPhone
    contactEmail
}
city
zip
schoolCode
canAccessGoReadApp
canAccessBoomMathApp
canAccessDataDashboard
canAccessBoomWriteApp
canAccessWonde
financeOfficerName
financeOfficerEmail
financeOfficerPhone
schoolPhoneNumber
goReadSubscriptionExpired
currentAcademicYear
localAuthority
hasSetWeekStart
weekStart
setupStep
studentsCounter
studentsUsingGoRead
urn
hasRegisteredInterestForWritePromo
classes {
    id
}
schoolSubscriptions {
    id
    isTrial
    subscriptionType
}
boomMathSubscription {
    id
    appName
    expiresAt
    status
    isTrial
    boomMathTrialStartedAt
    boomMathPaymentStartedAt
    subscribedSeats
    subscriptionCancelledAt
    nextPaymentDate
    payments {
        id
        status
        amount
        receiptUrl
        createdAt
    }
}
purchasedPacks {
    id
}
teachers {
    id
    roleId
}
stripeCustomerPaymentMethod {
    expMonth
    expYear
    last4
}
`;


export const teacherQuery = `
id
avatarUrl
displayName
name
lastName
firstName
email
expiredToken
isSchoolAdmin
lastTermsVersionApproved
roleId
subscriptionIntent
signupReason
school {
  ${schoolQuery}
}
studentsClasses {
    id
    name
    description
}
`;


export const renewalFragment = `
id
discount
duration
actualStudentsCounter
initialStudentsCounter
studentsCounter
savingsForTwoYearsSubscription
isSecondYearRenewal
editedBy {
    id
    displayName
}
editedAt
wondeAccess
dataDashboardAccess
urn
status
toBeProcessedAt         
`;